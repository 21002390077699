const getGrade = (value) =>{
    let grade = null
    if(!value) return null;
    
    if(value >3.9) grade = "A"
    else if(value>3.6) grade="B+"
    else if(value>3.2) grade="B"
    else if(value>2.9) grade="B-"
    else if(value>2.6) grade="C+"
    else if(value>2.2) grade="C"
    else if(value>1.9) grade="C-"
    else if(value>1.6) grade="D+"
    else if(value>1.2) grade="D"
    else if(value>0.9) grade="D-"
    else grade="F"

    return grade
}

export default {
    getGrade
}