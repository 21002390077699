<template>
  <b-row v-if="data" :key="data._id">
    <b-col cols="12" lg="10" 
      v-if="$can('manage', 'admin')"
    >
      <div>
        PE firm
        <b-form-group>
          <v-select
            v-model="parent"
            label="name"
            :options="parentCompanies"
            
          />
        </b-form-group>
      </div>
    </b-col>
    <b-col cols="12" lg="2" 
      v-if="$can('manage', 'admin')"
    >
      <b-button variant="primary" style="margin-top:20px" @click="downloadPEReport">Download PE Report</b-button>
    </b-col>
    <b-col cols="12" class="heading">
      <span font-weight="bold">{{ parent.name }}</span> Portfolio Grade: <span :class="getGrade(data.childTotal, true)">{{ getGrade(data.childTotal) }}</span>
    </b-col>

    <b-col md="4" offset-md="8">
      <b-form-checkbox
          class="heatmap-switch"
          name="check-button"
          switch
          v-model="showHeatmap"
        >
          Heatmap
        </b-form-checkbox>
    </b-col>

    <b-col cols="12" v-if="showHeatmap">
        <portfolioHeatMap :parentId="parent._id"/>
    </b-col>

    <b-row v-else>
      <b-col v-for="child in data.children" :key="child.name" md="3" sm = "6" ls="12" class="mt-3">
        <scoreColorBox :value="child" :parentId="parent._id"/>
      </b-col>
    </b-row>
    
    <!-- <b-col md="6" sm = "12">
      <b-card v-if="data && data.childAverage" title="Average Score by Company" >
        <rose-chart :value="data.childAverage" :key="data._id"/>
      </b-card>
      <div class="average" :key="data._id">
        {{ Math.round(10*data.childTotal)/10 }}
      </div>
    </b-col>
    <b-col md="6" sm="12">
      <b-card v-if="data && data.categoryAverage" title="Average Score by Category" >
        <doughnut-chart :value="data.categoryAverage" :key="data._id"/>
      </b-card>
      
    </b-col>
    <b-col cols="12">
      <average-scores :value="data.children" :key="data._id"/>
    </b-col> -->
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup, BCard, BButton, BFormCheckbox } from 'bootstrap-vue'

// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
// import { kFormatter } from '@core/utils/filter'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
// import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
// import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
// import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
// import AverageScores from './AverageScores.vue'
import vSelect from 'vue-select'
// import RoseChart from '@/layouts/components/chart/rose-chart.vue'
// import DoughnutChart from '@/layouts/components/chart/doughnut-chart.vue'
import scoreColorBox from "./scoreColorBox.vue"
import AxiosService from '@/Service/AxiosService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DarkToggler from '../../../@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Functions from '@/Constants/Functions'
import portfolioHeatMap from "./portfolioHeatMap.vue"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    vSelect,
    BFormGroup,
    DarkToggler,
    scoreColorBox, 
    BButton,
    BFormCheckbox,
    portfolioHeatMap
  },
  data() {
    return {
      data:null,
      parentCompanies:[],
      parent:null,
      showHeatmap:false
    }
  },
  methods:{
    init(){
      if(this.user.role == "admin")
        this.getParentCompany()
      else if(this.user.role=='parent')
        this.parent = this.user.entity;
    },
    getParentCompany(){
      AxiosService.get("/companies", {"type":"parent"}).then(({error, data})=>{
        if(error){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: `Some error occured while getting companies, Please try again or contact an admin`,
              },
          })
        }
        else {
          this.parentCompanies = data.rows;
          this.parent = data.rows[0];
        }
      })
    },
    downloadPEReport(){
      this.$store.dispatch("setLoader",true)
      AxiosService.downloadPdf(`/createPEReport?companyId=${this.parent._id}`).then((response)=>{
        this.$store.dispatch("setLoader",false)
        // console.log("response", response)
        if(response.error){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: response.error,
              },
          })
        return;
        }
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${this.parent.name} Report.pdf`
        link.click()
      })
    },
    getGrade(value, isCss){
      let grade = Functions.getGrade(value)
      if(!grade) return null
      if(isCss) return grade[0]
      return grade
    }
  },
  mounted() {
    this.$store.dispatch("getUser");
  },
  watch:{
    parent(newParent){
      AxiosService.get("/getPortfolioScore", {parent:newParent._id}).then(({error, data})=>{
        // console.log("data", data)
        if(error) {
          // console.log("error", error)
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: `Some error occured while getting analytics data, Please try again or contact an admin`,
              },
          })
        }
        else{
          this.data = data;
        }

      })
    },
    user(newVal){
      // console.log("user watch")
      if(newVal)
        this.init();
    }
  },
  computed:{
    user(){
        return this.$store.getters.getUser || {};
    }
  }
}
</script>

<style scoped>
  .average{
    position: absolute;
    left: calc(50% - 15px);
    top: 40%;
    z-index: 99;
    font-size: 20px;
    font-weight: bold;
  }
  .heading {
    font-size: 40px;
    font-weight: 600;
  }
  .heatmap-switch{
    margin-right:1.5rem;
    margin-left: auto;
    float: right;
  }
</style>