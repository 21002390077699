<template>
  <b-card
    no-body
    :class="{'open': visible}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header

      :class="{'collapsed': !visible}"
      class="header"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <slot name="header" >
        <table v-if="answer && answer.length>0" width="100%">
          <td width="5%"><span class="dot" :class="getGradeClass(grade)"></span></td>
          <td width="45%" class="lead collapse-title width:50%">{{ question }}</td>
          <td width="45%" v-if="typeof answer=='string'" class="lead collapse-title width:50%">{{ answer }}</td>
          <td width="45%" v-else>
            <ul>
              <li v-for="(item, index) in answer" :key="index">
                {{ item }}
              </li>
            </ul>
          </td>
        </table>
        <div v-else>
          You are all set
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import Functions from '@/Constants/Functions'


export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    question: {
      type: String,
      required: true,
    },
    answer:{
      required:true
    },
    grade: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    getGradeClass(value){
      let grade = Functions.getGrade(value)
      if(!grade) return ""
      return grade[0];
    }
  },
}
</script>

<style scoped>
.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.A{
  background-color: #0000CC;
}
.B{
  background-color:#008000;
}
.C{
  background-color:#FFD700;
}
.D{
  background-color:#FFA500;
}
.F{
  background-color:#FF0000;
}
.Z{
  background-color:#bbb
}
.header:hover{
  background-color: #eee;
  transition: 0.5s;
}
</style>