<template>
    <div style="width:100%">
        <heatmapChart v-if="data && !error" :result="data"/>
    </div>
    
</template>

<script>
import AxiosService from '@/Service/AxiosService'
import heatmapChart from '@/layouts/components/chart/heatmap-chart.vue'

export default {
    components: {
        heatmapChart
    },
    props:{
        parentId:{
            type:String,
            required:true
        }
    },
    mounted(){
        this.getParentCompanyResult()
    },
    data(){
        return {
            data:null,
            error:null
        }
    },
    methods:{
        getParentCompanyResult(){
            AxiosService.get("/getParentCompanyResult", {parent:this.parentId}).then(({error, message, data})=>{
                if(!data){
                    this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        variant: 'error',
                        text: `Some error occured while getting companies, Please try again or contact an admin`,
                    },
                    })
                }
                if(error){
                    this.error = error;
                    this.data = null;
                }
                else {
                    this.error = null;
                    this.data = data
                }
            })    
        }
    }
}
</script>