<template>
  <div :class="boxCss(value.value)">
    <feather-icon v-if="value.submitted && value.reportGenerated"
        icon="DownloadIcon"
        class="top-right"
        size="24"
        @click="downloadReport(value)"
    />
    <!-- <feather-icon v-else
        icon="DownloadIcon"
        class="top-right"
        size="24"
        :disabled="true"
    /> -->
    <div class="box-top mt-1">
      <!-- <div v-html="getLetterGrade(value.value)"></div> -->
      <div v-if="!value.submitted" class="circle not-submitted">?</div>
      <div v-else-if="value.value>3.9" class="circle A">A</div>
      <div v-else-if="value.value>3.6" class="circle B">B+</div>
      <div v-else-if="value.value>3.2" class="circle B">B</div>
      <div v-else-if="value.value>2.9" class="circle B">B-</div>
      <div v-else-if="value.value>2.6" class="circle C">C+</div>
      <div v-else-if="value.value>2.2" class="circle C">C</div>
      <div v-else-if="value.value>1.9" class="circle C">c-</div>
      <div v-else-if="value.value>1.6" class="circle D">D+</div>
      <div v-else-if="value.value>1.2" class="circle D">D</div>
      <div v-else-if="value.value>0.9" class="circle D">D-</div>
      <div v-else                      class="circle F">F</div>
    </div>
    <div class="portfolio-company text-center mt-1">
      {{ value.name }}
    </div>
    <div class="d-flex justify-content-around mt-1 bottom-button">
      <div v-if="value.submitted && value.reportGenerated">
        100% complete
      </div>
      <div v-else-if="!value.submitted">
        Not submitted
      </div>
      <div v-else>
        Report generating
      </div>
      <div class="results-link" @click="goToResultsPage(value, parentId)">
        review results
        <!-- <a :style="gradeCss(value.value)" :href="`${backendUrl}/createChildReport?childId=${value.id}`">Review results</a> -->
      </div>
    </div>
  </div>
    
</template>

<script>
import AxiosService from '@/Service/AxiosService'
import Config from "@/Constants/Config.json"

export default {
  components: {
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    parentId:{
      type: String
    }
  },
  data() {
    return {
    }
  },
  // mounted(){
  //   console.log("prop", this.value, this.parentId);
  // },
  // watch:{
  //   value(val){
  //     console.log("val", val)
  //   }
  // },
  methods:{
    boxCss(value){
      // console.log("value", value)
      if(value<1) return "box-F"
      else return "box"
    },
    gradeCss(value){
      if(value<1) return "color: #FF0000"
      else return "color: #134270"
    },
    downloadReport(child){
      this.$store.dispatch("setLoader",true)

      AxiosService.downloadPdf(`/createChildReport?companyId=${child.id}`).then((response)=>{
        this.$store.dispatch("setLoader",false)
        console.log("response", response)
        if(response.error){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: response.error,
              },
          })
        return;
        }
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${child.name} Report.pdf`
        link.click()
      })
    },
    goToResultsPage(value, parentId){
      this.$router.push({name:"Result", params:{childId:value.id, parentId:parentId, grade:value.value}})
    }
  },
  computed:{
    backendUrl(){
      return Config.backendUrl;
    }
  }
}
</script>

<style scoped>
  .box{
    border: 2px solid  #1954ec;
    color: #1954ec;
    font-weight: 600;
    padding: 10px 0 50px 0;
    /* color: #134270 !important; */
  }
  .box-F{
    border: 4px solid  #FF0000;
    font-weight: 600;
    padding: 10px 0 50px 0;
    color: #FF0000 !important;

  }
  .box-top{
    display:flex;
    justify-content: center;
  }
  .portfolio-company{
    font-size: 15px;
  }
  .circle{
    /* text-align: center; */
    font-size: 30px;
    border: 2px solid #134270;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: inline-flex; /* you can also use 'grid' or just 'flex'*/
    justify-content: center;
    align-items: center;
  }
  .not-submitted{
    color: #aaa;
    border: 2px solid #aaa;
  }
  .A{
    color: #0000CC;
    border: 2px solid #0000CC;
  }
  .B{
    color:#008000;
    border: 2px solid #008000;
  }
  .C{
    color:#FFD700;
    border: 2px solid #FFD700;
  }
  .D{
    color:#FFA500;
    border: 2px solid #FFA500;
  }
  .F{
    color:#FF0000;
    border: 2px solid #FF0000;
  }
  .results-link{
    cursor: pointer;
    text-decoration: underline;
  }
  .top-right {
    position: absolute;
    right:20px;
    top:10px;
    font-size: 20px;
    cursor:pointer;
  }
  .bottom-button{
    color:#545454 !important;
    font-family: 'Helvetica Now', Helvetica, Arial, serif;
  }
</style>